import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/register/register.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { LoginSidebarComponent } from './auth/component/login-sidebar/login-sidebar.component';
import { AuthFooterComponent } from './auth/component/auth-footer/auth-footer.component';
import { RegisterSidebarComponent } from './auth/component/register-sidebar/register-sidebar.component';
import { Register2Component } from './auth/register2/register2.component';
import { SharedModule } from './utils/shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JQ_TOKEN } from './utils/jQuery.service';
const jQuery = window['$'];

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    LoginSidebarComponent,
    AuthFooterComponent,
    RegisterSidebarComponent,
    Register2Component,
  ],
  imports: [
    SharedModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule
  ],
  providers: [
    { provide: JQ_TOKEN, useValue: jQuery },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
