import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-register-sidebar',
  templateUrl: './register-sidebar.component.html',
  styleUrls: ['./register-sidebar.component.scss']
})
export class RegisterSidebarComponent implements OnInit {

  isOpen = false;
  constructor() { }

  ngOnInit() {
  }
  sidebarToggle() {
    this.isOpen = !this.isOpen
  }

}
