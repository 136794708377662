import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideDrawerComponent } from '../side-drawer/side-drawer.component';
import { SideDrawerTriggerDirective } from '../directives/side-drawer-trigger.directive';
import { ClosableSideDrawerComponent } from '../closable-side-drawer/closable-side-drawer.component';
import { ClosableSideDrawerTriggerDirective } from '../directives/closable-side-drawer-trigger.directive';



@NgModule({
  declarations: [
    SideDrawerComponent,
    SideDrawerTriggerDirective,
    ClosableSideDrawerComponent,
    ClosableSideDrawerTriggerDirective
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    SideDrawerComponent,
    ClosableSideDrawerComponent,
    SideDrawerTriggerDirective,
    ClosableSideDrawerTriggerDirective
  ]
})
export class SharedModule { }
