import { Component, OnInit, Input, Inject } from '@angular/core';
import { JQ_TOKEN } from '../jQuery.service';

@Component({
  selector: 'app-closable-side-drawer',
  templateUrl: './closable-side-drawer.component.html',
  styleUrls: ['./closable-side-drawer.component.scss']
})
export class ClosableSideDrawerComponent implements OnInit {
  @Input() position: string; // left or right
  @Input() elementId: string;
  @Input() headerTitle: string;

  constructor(@Inject(JQ_TOKEN) private $: any) { }

  ngOnInit() {
  }

  closeDrawer() {
    // document.body.classList.remove('right-drawer');
  }

}
