import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-login-sidebar',
  templateUrl: './login-sidebar.component.html',
  styleUrls: ['./login-sidebar.component.scss']
})
export class LoginSidebarComponent implements OnInit {

  isOpen = false;
  constructor() { }

  ngOnInit() {
  }
  sidebarToggle() {
    this.isOpen = !this.isOpen
  }
}
