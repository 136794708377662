import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-register2',
  templateUrl: './register2.component.html',
  styleUrls: ['./register2.component.scss']
})
export class Register2Component implements OnInit {

  list:any;
  selected :any;
  constructor() { }

  ngOnInit() { 
    this.list = [
    'English',
    'Teaching',
    'Mathematics',
    'Math',
    'Science',
    'Biology',
    'K12',
    'Adult Learning',
    'School Prep'
 ]; 
  }

select(item) {
    this.selected = item; 
};
isActive(item) {
    return this.selected === item;
};
}
